import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import * as query from 'querystringify'
import Header from 'components/common/Header'
import AvatarList from 'components/features/StaffCard/AvatarList'
import Scanner from 'components/common/Scanner'
import Active from 'assets/svg/active.svg'
import ActiveGrey from 'assets/svg/active-grey.svg'
import { GrNotes } from 'react-icons/gr'
import {
  date,
  dateToFormat,
  getMin,
  isEmptyObject,
  redirect,
  route,
} from 'helpers'
import { resetModals, closeModal, showModal } from 'store/modal/actions'
import {
  fetchOneTaskAssignmentStart,
  fetchOneTaskAssignmentSlim1Start,
  fetchPauseReasonsStart,
  resetAssignments,
  updateTaskAssignment,
  updateTaskAssignmentWithoutFetch,
} from 'store/taskAssignment/actions'
import {
  updateOrderProductPickLocation,
  checkStockOrder,
} from 'store/orderProductPickLocation/actions'
import {
  createWarehouseCase,
  updateWarehouseCase,
  updateWarehouseCaseSold,
} from 'store/warehouseCase/actions'
import { fetchLocationsStart } from 'store/location/actions'
import { updatePowerPickAuto } from 'store/order/actions'
import {
  POWER_PICKING_MOVE_TO_LANE_PAGE,
  POWER_PICKING_WRAP_PALLET_PAGE,
  START_POWER_PICKING_PAGE,
  POWER_PICKING_PULLSHEET_PAGE,
  POWER_PICKING_LIST_PAGE,
} from 'constants/routes'
import { LOCATION_TYPE_FILTER, WAREHOUSE_FILTER } from 'constants/filters'
import { LOCATION_TYPE } from 'constants/locationTypes'
import { TASK_STATUSES, TASK_TYPES } from 'constants/taskAssignment'
import { createDamageCases } from 'store/product/actions'
import { PROCESS_TYPES } from 'constants/process'
import Button from 'components/common/Button'
import { ORDER_PICK_STATUSES, ORDER_STATUSES } from 'constants/order'
import { createOrderPickPallet } from 'store/orderPickPallet/actions'
import { updateOrderPalletStart } from 'store/orderPallet/actions'

import ExclamationSign from 'assets/svg/exclamation.svg'
import RestockContent from '../RestockContent'
import { updateOrderPickStatusStart } from 'store/orderPickStatus/actions'
import './style.scss'
import { BsChevronDoubleLeft, BsPencilSquare } from 'react-icons/bs'
import { processPick } from 'store/order/actions'
import ExclamationSmall from 'assets/svg/exclamation-sign.svg'
import { MdWarning } from 'react-icons/md'

const StartPowerPickingPage = (props) => {
  const {
    user,
    match,
    lastRefresh,
    taskAssignment,
    damageLocation,
    modalDamagePutawayLocationIsOpen,
    isPowerPickAuto,

    showModal,
    resetModals,
    closeModal,
    fetchLocations,
    fetchPauseReasons,
    updateTaskAssignment,
    updateTaskAssignmentWithoutFetch,
    updateOrderProductPickLocation,
    updateOrderPickStatus,
    updateWarehouseCase,
    updateWarehouseCaseSold,
    resetAssignments,
    createDamageCases,
    fetchOneTaskAssignment,
    fetchOneTaskAssignmentSlim1,
    createOrderPickPallet,
    updateOrderPalletStart,
    checkStockOrder,
    processPick,
    isAutoPickOnlyUser,
    updatePowerPickAuto,
  } = props

  const INIT_STATUS_PANEL = {
    isProductScanned: isPowerPickAuto ? false : true,
    text: 'Scan Product UPC',
    icon: <ActiveGrey className="scan-indicator" />,
  }

  const [restockedLocations, setRestockedLocations] = useState([])
  const [showRestockedLocations, setShowRestockedLocations] = useState(false)
  const [statusPanel, setStatusPanel] = useState(INIT_STATUS_PANEL)
  const [pickedQty, setPickedQty] = useState(0)
  const [matchId, setMatchId] = useState(match.params.id)
  const [isFreshlyLoaded, setIsFreshlyLoaded] = useState(true)

  if (matchId !== match.params.id) {
    setMatchId(match.params.id)
  }
  const id = matchId

  const onPageLoad = () => {
    setShowRestockedLocations(false)
    resetAssignments()

    fetchLocations(getParamsForDamageLocation())
    fetchPauseReasons()

    // fetchOneTaskAssignment({ id })
    fetchOneTaskAssignmentSlim1({ id })
    if (isAutoPickOnlyUser && !isPowerPickAuto) {
      updatePowerPickAuto(true)
    }
  }

  const onContinuedLoad = () => {
    // fetchOneTaskAssignment({ id })
    // fetchOneTaskAssignmentSlim1({id})
  }

  const getParamsForDamageLocation = () =>
    query.stringify(
      {
        [WAREHOUSE_FILTER]: user.warehouseId,
        [LOCATION_TYPE_FILTER]: LOCATION_TYPE.DAMAGE_TYPE_ID,
      },
      true,
    )

  const updateAssignment = (data) => {
    updateTaskAssignment({
      id: data.id,
      data,
    })

    resetAssignments()

    resetModals()
  }

  const getNextTaskAssignment = () => {
    const notPickedLocations = taskAssignment.entity.pickLocations
      .filter((item) => {
        return (
          item.warehouse_location.warehouse_case !== undefined &&
          item.warehouse_location.warehouse_case.location_case_qty > 0
        )
      })
      .filter(
        (item) =>
          item.location_pick_quantity - item.unavailable_quantity !==
            item.picked_quantity ||
          parseInt(item.task_assignment_id) === parseInt(id),
      ) //location not picked yet

    //find next id after current id
    let currentFound = false
    let nextItem = notPickedLocations.find((item) => {
      if (currentFound) {
        return true
      }
      currentFound = parseInt(item.task_assignment_id) === parseInt(id)
    })

    let nextItemReturn
    switch (true) {
      case !!nextItem:
        nextItemReturn = nextItem
        break
      case !!notPickedLocations[0]:
        nextItemReturn = notPickedLocations[0]
        break
      default:
        nextItemReturn = null
    }

    //don't return the current item
    if (
      nextItemReturn &&
      parseInt(nextItemReturn.task_assignment_id) === parseInt(id)
    ) {
      nextItemReturn = null
    }

    return nextItemReturn
  }

  const getNextTaskAssignmentId = () => {
    const nextAssignment = getNextTaskAssignment()
    return nextAssignment ? parseInt(nextAssignment.task_assignment_id) : null
  }

  const flowToNextLocationOrToTaskList = () => {
    const nextTaskAssignmentId = getNextTaskAssignmentId()

    if (nextTaskAssignmentId) {
      redirect(START_POWER_PICKING_PAGE.replace(':id', nextTaskAssignmentId))
      setStatusPanel(INIT_STATUS_PANEL)
      resetAssignments()
    } else {
      setShowRestockedLocations(true)
    }
  }

  const onComplete = () => {
    resetModals()

    if (isPowerPickAuto) {
      flowToNextLocationOrToTaskList()
    } else {
      resetAssignments()

      redirect(
        POWER_PICKING_PULLSHEET_PAGE.replace(':id', taskAssignment.process.id),
      )
    }
  }

  const onCloseAll = () => {
    if (taskAssignment.status !== TASK_STATUSES.FINISHED_TYPE) {
      updateAssignment({
        id: taskAssignment.id,
        status: TASK_STATUSES.CREATED_TYPE,
      })
    }
    resetAssignments()
  }

  const onCloseAutoPickOn = () => {
    onCloseAll()
    redirect(POWER_PICKING_LIST_PAGE)
  }

  const onCloseAutoPickOff = () => {
    onCloseAll()
    redirect(
      POWER_PICKING_PULLSHEET_PAGE.replace(':id', taskAssignment.process.id),
    )
  }

  const onSkip = () => {
    flowToNextLocationOrToTaskList()
  }

  // NOTE: Was only called in Damage section - see below - commenting out but leaving for reference. 2022.03
  // const isOrderPickPalletCreated = () => {
  //   const currentPallet =
  //     taskAssignment.entity.orderPallets[
  //       taskAssignment.entity.orderPallets.length - 1
  //     ]
  //   return !!currentPallet.order_pick_pallets.find(
  //     (item) => item.order_product_pick_location.id === pickLocation.id,
  //   )
  // }

  const onChangeAssignment = () => {
    showModal({
      name: 'modalPending',
    })
    if (!isEmptyObject(taskAssignment)) {
      resetModals()
      if (taskAssignment.status === TASK_STATUSES.CREATED_TYPE) {
        updateAssignment({
          id: taskAssignment.id,
          status: TASK_STATUSES.STARTED_TYPE,
        })
      }

      if (taskAssignment.status === TASK_STATUSES.PAUSED_TYPE) {
        showModal({
          name: 'modalResume',
          onResume: onConfirmResume,
          className: 'modal--tasks',
        })
      }

      const pickLocation = taskAssignment.entity.orderProductPickLocation

      const pickLocations = taskAssignment.entity.pickLocations
      setRestockedLocations(
        pickLocations.filter((item) => item.restock_required),
      )

      if (pickLocation) {
        const pickedCases = pickLocation.picked_quantity
        const unavailableCases = pickLocation.unavailable_quantity
        const isPickedAllCases =
          pickedCases + unavailableCases === pickLocation.location_pick_quantity
        const noCasesInLocation = !!warehouseCase
          ? !warehouseCase.location_case_qty
          : true

        if (statusPanel.isProductScanned && !isPowerPickAuto) {
          showModalPickedQtyWithData()
        }

        if (isPickedAllCases || (!isPowerPickAuto && !isFreshlyLoaded)) {
          onComplete()
        } else if (noCasesInLocation) {
          updateOrderProductPickLocation({
            request: {
              id: pickLocation.id,
              // task_assignment_id: taskAssignment.id,
              data: {
                id: pickLocation.id,
                product_unavailable:
                  pickLocation.location_pick_quantity -
                  pickLocation.picked_quantity,
              },
            },
            afterAction: () => {},
          })

          flowToNextLocationOrToTaskList()
        }
      }
    }
  }

  useEffect(onPageLoad, [])
  useEffect(onContinuedLoad, [matchId])
  useEffect(onChangeAssignment, [taskAssignment])

  if (isEmptyObject(taskAssignment)) {
    return ''
  }

  const pickLocation = taskAssignment.entity.orderProductPickLocation

  if (!pickLocation) {
    const nextTaskAssignment = taskAssignment.entity.next_assignment
    const isNextExists = nextTaskAssignment !== null

    const goToNextTask = () => {
      updateTaskAssignmentWithoutFetch({
        id: taskAssignment.id,
        data: {
          id: id,
          end_date: dateToFormat(date(), 'YYYY-MM-DD'),
          status: TASK_STATUSES.FINISHED_TYPE,
        },
      })

      redirect(
        isNextExists
          ? START_POWER_PICKING_PAGE.replace(':id', nextTaskAssignment.id)
          : POWER_PICKING_LIST_PAGE,
      )
    }

    const endTheOrder = () => {
      updateTaskAssignmentWithoutFetch({
        id: taskAssignment.id,
        data: {
          id: id,
          end_date: dateToFormat(date(), 'YYYY-MM-DD'),
          status: TASK_STATUSES.FINISHED_TYPE,
        },
      })

      const orderPallet =
        taskAssignment.entity.orderPallets.length > 0
          ? taskAssignment.entity.orderPallets[0]
          : null

      if (orderPallet) {
        const orderTaskAssignment = orderPallet.tasks.find(
          (item) => item.task_type.id === TASK_TYPES.PICKING_TYPE,
        ).task_assignments[0]

        if (orderTaskAssignment) {
          updateTaskAssignment({
            id: orderTaskAssignment.id,
            data: {
              id: orderTaskAssignment.id,
              end_date: dateToFormat(date(), 'YYYY-MM-DD'),
              status: TASK_STATUSES.FINISHED_TYPE,
            },
          })
        }
      }

      taskAssignment.entity.pickLocations.forEach((item) => {
        updateTaskAssignment({
          id: item.task_assignment_id,
          data: {
            id: item.task_assignment_id,
            end_date: dateToFormat(date(), 'YYYY-MM-DD'),
            status: TASK_STATUSES.FINISHED_TYPE,
          },
        })
      })

      updateOrderPickStatus({
        id: taskAssignment.entity.orderPickStatus.id,
        data: {
          id: taskAssignment.entity.orderPickStatus.id,
          status_id: ORDER_PICK_STATUSES.STATUS_COMPLETED_ORDER,
          order_id: taskAssignment.entity.order.orders_id,
        },
      })
      redirect(POWER_PICKING_LIST_PAGE)
    }

    return (
      <div className="tasks-page tasks-page--start-picking">
        <Header>
          <div className="title">Pick location is not defined</div>
        </Header>
        <div className="no-tasks">
          <div className="no-tasks__time">
            <div>
              <b>Order:</b> {taskAssignment.entity.order.orders_id}
            </div>
            <div>
              <b>Assignment id:</b> {taskAssignment.id}
            </div>
          </div>
          <div className="no-tasks__text">
            Pick location for this task assignment wasn't defined when you start
            an order!
          </div>
          <Button onClick={goToNextTask}>Go to the next</Button>
          <Button onClick={endTheOrder}>End order</Button>
        </div>
      </div>
    )
  }

  const order = taskAssignment.entity.order
  const isShippingOrder =
    order.orders_status === ORDER_STATUSES.STATUS_SCHEDULED_TO_SHIP
  const isPickupOrder =
    order.orders_status === ORDER_STATUSES.STATUS_SCHEDULED_TO_PICKUP ||
    !!order.pickup_date

  const warehouseCase = pickLocation.warehouseLocation.warehouse_cases_in_location.find(
    (item) => item.product_id === pickLocation.product.products_id,
  )
  const allPickLocations = taskAssignment.entity.pickLocations

  // NOTE: Was only called in Damage section - see below - commenting out but leaving for reference. 2022.03
  // const showModalWrongLocationWithInfo = () => {
  //   showModal({
  //     name: 'modalWrongLocation',
  //     onAccept: onCloseModalWrongLocation,
  //     text: modalDamagePutawayLocationIsOpen
  //       ? getDamageLocationName()
  //       : getLocationName(),
  //     className: 'modal--tasks',
  //   })
  // }

  const showModalWrongProductUpc = () => {
    showModal({
      name: 'modalWarning',
      onAccept: resetModals,
      onDeny: resetModals,
      text: 'Wrong Product UPC',
      className: 'modal--tasks',
      modalIcon: (
        <ExclamationSign className="icon--restock icon--exclamation" />
      ),
    })
  }

  const onScan = (barcode) => {
    if (!statusPanel.isProductScanned) {
      const checkIfMatches = () => {
        for (const [key, value] of Object.entries(
          pickLocation.product.products_upcs_case,
        )) {
          if (value.products_upc_case.toString() === barcode.toString()) {
            return true
          }
        }
        return false
      }
      const isCorrect = checkIfMatches()

      switch (true) {
        case !isCorrect:
          showModalWrongProductUpc()
          break
        case isCorrect:
          setStatusPanel({
            ...statusPanel,
            icon: <Active className="scan-indicator" />,
          })
          setTimeout(
            () =>
              setStatusPanel({
                ...statusPanel,
                isProductScanned: true,
              }),
            300,
          )
          showModalPickedQtyWithData()
          break
        default:
      }
    }
  }

  const denyPickedQtyModal = () => {
    setStatusPanel({
      ...statusPanel,
      isProductScanned: false,
    })
    resetModals()
  }

  const showModalPickedQtyWithData = () => {
    const remainToPick =
      pickLocation.location_pick_quantity - pickLocation.picked_quantity

    showModal({
      name: 'modalPickedQty',
      value: getMin(remainToPick, getAvailableCaseQuantity()),
      onConfirm: onConfirmPickedQty,
      onDeny: denyPickedQtyModal,
      maxValue: getMin(remainToPick, getAvailableCaseQuantity()),
      className: 'modal--tasks modal--damaged',
    })
  }
  const getAllOrderCasesQuantity = () =>
    allPickLocations.reduce((sum, item) => sum + item.location_pick_quantity, 0)
  const getAllPickedCasesQuantity = () =>
    allPickLocations.reduce((sum, item) => sum + item.picked_quantity, 0)

  // NOTE: This block is related to Damage, and is not functioning at the moment. So commenting out but leaving for reference. 2022.03
  // I think the idea was to be able to pick damaged cases, if no regular cases were available...
  // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
  // const openModalDamageQty = () => {
  //   resetModals()
  //   showModalDamageQtyWithData()
  // }

  // const showModalDamageQtyWithData = () => {
  //   showModal({
  //     name: 'modalDamageQty',
  //     value: 1,
  //     onConfirm: openModalDamagePutawayLocation,
  //     onDeny: resetModals,
  //     maxValue:
  //       pickLocation.location_pick_quantity - pickLocation.picked_quantity,
  //     className: 'modal--tasks modal--damaged',
  //   })
  // }

  // const openModalDamagePutawayLocation = (value) => {
  //   resetModals()
  //   showModal({
  //     name: 'modalDamagePutawayLocation',
  //     onCaseUpcCorrect: onConfirmDamageLocationScan,
  //     onCaseUpcNotCorrect: showModalWrongLocationWithInfo,
  //     onBack: openModalDamageQty,
  //     damageQuantity: value,
  //     text: getDamageLocationName(),
  //     upc: getDamageLocationUPC(),
  //     className: 'modal--tasks modal--damaged',
  //   })
  // }

  // const getLocationName = () =>
  //   `${pickLocation.warehouseLocation.regular_location.aisle_id.name}-${pickLocation.warehouseLocation.regular_location.bay_id.id}-${pickLocation.warehouseLocation.regular_location.level}`

  // const getDamageLocationName = () =>
  //   damageLocation ? `${damageLocation.aisle}-${damageLocation.bay}` : ''

  // const getDamageLocationUPC = () => (damageLocation ? damageLocation.upc : ' ')

  // const onConfirmDamageLocationScan = (value) => {
  //   resetModals()
  //   const isAllCasesPicked =
  //     pickLocation.location_pick_quantity -
  //       pickLocation.unavailable_quantity -
  //       value ===
  //     pickLocation.picked_quantity

  //   if (warehouseCase.location_case_qty <= value) {
  //     // createRestockProcessesToLocationFromStorage({
  //     //   warehouse_location_id: warehouseCase.id,
  //     // })  // commented out re: so-327, but leaving this code for context.
  //   }

  //   updateOrderProductPickLocation({
  //     request: {
  //       id: pickLocation.id,
  //       task_assignment_id: taskAssignment.id,
  //       data: {
  //         id: pickLocation.id,
  //         unavailable_quantity: value,
  //       },
  //     },
  //   })

  //   createDamageCases({
  //     request: {
  //       data: {
  //         product_id: pickLocation.product.products_id,
  //         process_type: PROCESS_TYPES.ORDER_PROCESS_TYPE,
  //         warehouse_location_id: damageLocation.id,
  //         count_damaged_cases: value,
  //       },
  //     },
  //   })

  //   updateWarehouseCase({
  //     id: warehouseCase.id,
  //     data: {
  //       id: warehouseCase.id,
  //       location_case_qty:
  //         warehouseCase.location_case_qty -
  //         (value - pickLocation.unavailable_quantity),
  //     },
  //   })

  //   updateWarehouseCaseSold({
  //     id: warehouseCase.id,
  //     data: {
  //       products_id: warehouseCase.product_id,
  //       quantity: value - pickLocation.unavailable_quantity,
  //     },
  //   })

  //   if (isAllCasesPicked) {
  //     updateTaskAssignmentWithoutFetch({
  //       id: id,
  //       data: {
  //         id: id,
  //         end_date: dateToFormat(date(), 'YYYY-MM-DD'),
  //         status: TASK_STATUSES.FINISHED_TYPE,
  //       },
  //     })

  //     if (!isOrderPickPalletCreated()) {
  //       createOrderPickPallet({
  //         data: {
  //           order_pallet_id: getLastOrderPallet().id,
  //           order_product_pick_location_id:
  //             taskAssignment.entity.orderProductPickLocation.id,
  //           pick_pallet_quantity: pickLocation.picked_quantity,
  //         },
  //       })
  //     }

  //     setPickedQty(0)
  //   }
  // }
  // ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  const getLastOrderPallet = () =>
    taskAssignment.entity.orderPallets[
      taskAssignment.entity.orderPallets.length - 1
    ]

  const onConfirmPickedQty = (value) => {
    setIsFreshlyLoaded(false)

    closeModal('modalPickedQty')
    showModal({
      name: 'modalPending',
    })
    processPick({
      request: {
        data: {
          pick_location: {
            id: pickLocation.id,
          },
          pick_quantity: value,
          task_assignment_id: taskAssignment.id,
        },
      },
      afterAction: (data) => {
        if (data.message === 'SUCCESS') {
          setPickedQty(data.item.picked_quantity)
        } else {
          showModal({
            name: 'modalWarning',
            text: 'Insufficient Cases in Location! Click to Retry Pick.',
            onAccept: () => {
              closeModal('modalWarning')
              setPickedQty(0)
              setStatusPanel(INIT_STATUS_PANEL)
              onPageLoad()
            },
            onDeny: null,
            className: 'modal--tasks modal--pause',
            modalIcon: <ExclamationSmall />,
          })
        }
      },
    })
  }

  const onConfirmResume = () => {
    showModal({
      name: 'modalPending',
    })

    updateAssignment({
      id: taskAssignment.id,
      status: TASK_STATUSES.STARTED_TYPE,
      is_resume_task: true,
    })
  }

  // const onCloseModalWrongLocation = () => closeModal('modalWrongLocation')

  const getPickedCases = () => (
    <>
      {pickLocation.picked_quantity === 0 ? (
        ''
      ) : (
        <div className="pick-status">
          {pickLocation.picked_quantity}/{pickLocation.location_pick_quantity}{' '}
          Picked
        </div>
      )}
    </>
  )

  const getAvailableCaseQuantity = () =>
    getMin(pickLocation.location_pick_quantity, warehouseCase.location_case_qty)

  const checkStockAfterAction = () => {
    redirect(
      POWER_PICKING_PULLSHEET_PAGE.replace(':id', taskAssignment.process.id),
    )
  }

  const onRefresh = () => {
    checkStockOrder({
      request: {
        process_id: taskAssignment.process.id,
        task_assignment_id: taskAssignment.id,
      },
      afterAction: checkStockAfterAction,
    })
  }

  const showModalConfirmWrap = () => {
    showModal({
      name: 'modalConfirm',
      onAccept: () => {
        onClickFullOrWrapBtn()
      },
      onDeny: resetModals,
      className: 'modal--picking-task',
      children: (
        <div className="modal--content-centered">
          <MdWarning style={{ fontSize: '75px', color: '#ffbf00' }} />
          <p className="text--bold">Wrap Pallet</p>
          <p>Are you sure you want continue?</p>
          <p>(This can't be undone)</p>
        </div>
      ),
    })
  }

  const onClickFullOrWrapBtn = () => {
    resetModals()
    getNextTaskAssignment()

    if (pickedQty) {
      createOrderPickPallet({
        data: {
          order_pallet_id: getLastOrderPallet().id,
          order_product_pick_location_id:
            taskAssignment.entity.orderProductPickLocation.id,
          pick_pallet_quantity: pickedQty,
        },
      })
    }

    updateOrderPalletStart({
      request: {
        id: getLastOrderPallet().id,
        data: {
          id: getLastOrderPallet().id,
          status_id: ORDER_PICK_STATUSES.STATUS_FULL,
          updated_by_user_id: user.id,
        },
      },
    })

    setPickedQty(0)

    redirect(
      route(POWER_PICKING_WRAP_PALLET_PAGE, {
        orderPalletId: getLastOrderPallet().id,
        nextTaskAssignmentId: taskAssignment.id,
      }),
    )
  }

  const showPickingTaskContent = () => {
    const currentPallet =
      taskAssignment.entity.orderPallets[
        taskAssignment.entity.orderPallets.length - 1
      ]
    const isShownActionButton =
      !!currentPallet.order_pick_pallets.length || !!pickedQty

    return (
      <div className="content">
        <div
          className={`content__section${
            pickLocation.product.is_top_stack === 1 ? ' item--top-stack' : ''
          }`}
        >
          <div className="dock__new-wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">
                {`${pickLocation.warehouseLocation.regular_location.aisle_id.name}-${pickLocation.warehouseLocation.regular_location.bay_id.id}-${pickLocation.warehouseLocation.regular_location.level}`}
                <span className="location-case-qty">
                  ({warehouseCase.location_case_qty})
                </span>
              </span>
            </div>
            <div className="dock">
              <span className="dock__label">QTY</span>
              <span className="dock__name">
                {pickLocation.location_pick_quantity -
                  pickLocation.picked_quantity -
                  pickLocation.unavailable_quantity}
              </span>
            </div>
          </div>
          <h3 className="fireworks-name-new">
            {pickLocation.productDescription.products_name}
          </h3>
          <ul className="fireworks-new">
            <li className="fireworks-new__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {pickLocation.product.products_model}
              </span>
            </li>
            <li>
              {!!pickLocation.product.is_top_stack && (
                <span className="red__bold">Top Stack</span>
              )}
            </li>
            <li className="fireworks-new__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name__smaller">
                {pickLocation.product.products_manf_model}
              </span>
            </li>
          </ul>
        </div>
        <div className="pick-status">
          <div>
            <br />
            {statusPanel.text} {statusPanel.icon}
            <br />
            <br />
            {getPickedCases()}
          </div>
        </div>
        <div className="content__section content__section--white">
          &nbsp;
          {isShownActionButton && isPowerPickAuto ? (
            <Button
              className="button--wrap-on-auto-pick"
              onClick={(e) => showModalConfirmWrap('wrap', e)}
            >
              Wrap
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }

  const nextAssignment = getNextTaskAssignment()

  const skipTo = nextAssignment
    ? 'Next: ' +
      nextAssignment.warehouse_location.regular_location.aisle_id.name +
      '-' +
      nextAssignment.warehouse_location.regular_location.bay_id.name +
      '-' +
      nextAssignment.warehouse_location.regular_location.level +
      ' ' +
      nextAssignment.product.products_model +
      ' (' +
      nextAssignment.order_products_quantity +
      ')'
    : ''

  const showModalOrderNotes = () => {
    showModal({
      name: 'modalOrderNotes',
      onClose: resetModals,
      order: taskAssignment.entity.order,
    })
  }

  return (
    <div className="tasks-page start-power-picking-page tasks-page--start-picking">
      {modalDamagePutawayLocationIsOpen ? (
        ''
      ) : (
        <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      )}

      <Header>
        {nextAssignment ? (
          <div className="text--skip" onClick={onSkip}>
            Skip
          </div>
        ) : (
          <div></div>
        )}
        {isPowerPickAuto &&
          (Object.keys(taskAssignment.entity.order.shipping_notes).length > 0 ||
            Object.keys(taskAssignment.entity.order.warehouse_notes).length >
              0) && (
            <GrNotes
              className="order-notes-icon-spp"
              onClick={showModalOrderNotes}
            />
          )}
        <div className="title">
          <span>
            Order {taskAssignment.entity.order.orders_id}
            <br />
            {getAllPickedCasesQuantity()}/{getAllOrderCasesQuantity()} Cases
            <br />
            {taskAssignment &&
              taskAssignment.entity.order.estimated_pallets}{' '}
            {taskAssignment &&
            taskAssignment.entity.order.estimated_pallets === 1
              ? 'pallet'
              : 'pallets'}{' '}
            est.
          </span>
          {taskAssignment.taskTeam.length ? (
            <AvatarList items={taskAssignment.taskTeam} />
          ) : (
            ''
          )}
        </div>
        <div
          className="text--close"
          onClick={isPowerPickAuto ? onCloseAutoPickOn : onCloseAutoPickOff}
        >
          Close
        </div>
      </Header>
      {showRestockedLocations ? (
        <RestockContent
          type={isShippingOrder ? 'wrap' : isPickupOrder ? 'full' : ''}
          time={dateToFormat(lastRefresh, 'HH:mm')}
          productCount={restockedLocations.length}
          onRefresh={onRefresh}
          onAction={showModalConfirmWrap}
        />
      ) : (
        showPickingTaskContent()
      )}
      <div className="skipto--info">{skipTo}</div>
    </div>
  )
}

StartPowerPickingPage.propTypes = {
  modals: PropTypes.object,
  taskAssignment: PropTypes.object,
  damageLocation: PropTypes.object,
  createOrderPickPallet: PropTypes.func,
}

const mapStateToProps = (state) => ({
  taskAssignment: state.taskAssignment.item,
  lastRefresh: state.taskAssignment.lastRefresh,
  damageLocation: state.location.list[0],
  modalDamagePutawayLocationIsOpen: !!state.modal.openModalsInfo.find(
    (item) => item.name === 'modalDamagePutawayLocation',
  ),
  isPowerPickAuto: state.order.isPowerPickAuto,
  isAutoPickOnlyUser: state.auth.user.user.warehouseStaff.is_auto_pick,
})

const mapDispatchToProps = {
  createWarehouseCase: createWarehouseCase,

  updateOrderProductPickLocation: updateOrderProductPickLocation,
  updateWarehouseCase: updateWarehouseCase,
  updateWarehouseCaseSold: updateWarehouseCaseSold,
  updateTaskAssignment: updateTaskAssignment,
  updateTaskAssignmentWithoutFetch: updateTaskAssignmentWithoutFetch,

  fetchOneTaskAssignment: fetchOneTaskAssignmentStart,
  fetchOneTaskAssignmentSlim1: fetchOneTaskAssignmentSlim1Start,
  fetchPauseReasons: fetchPauseReasonsStart,
  fetchLocations: fetchLocationsStart,

  showModal: showModal,

  closeModal: closeModal,

  resetModals: resetModals,
  resetAssignments: resetAssignments,

  createDamageCases: createDamageCases,
  createOrderPickPallet: createOrderPickPallet,
  updateOrderPalletStart: updateOrderPalletStart,

  updateOrderPickStatus: updateOrderPickStatusStart,
  checkStockOrder: checkStockOrder,
  processPick: processPick,

  updatePowerPickAuto: updatePowerPickAuto,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StartPowerPickingPage)

import { call, put, takeLatest } from 'redux-saga/effects'
import {
  fetchOneTaskAssignmentFail,
  fetchOneTaskAssignmentStart,
  fetchOneTaskAssignmentSuccess,
  fetchOneTaskAssignmentSlim1Success,
  fetchPauseReasonsSuccess,
  fetchTaskAssignmentListFail,
  fetchTaskAssignmentListStart,
  fetchTaskAssignmentListSuccess,
  completeOrderPickSuccess,
  types,
} from 'store/taskAssignment/actions'
import {
  fetchMany,
  createMany,
  deleteMany,
  fetchOne,
  fetchOneSlim1,
  update,
  create,
  deleteOne,
  fetchPauseReasons,
  completeOrderPick,
  assignTeamToUnload,
} from 'api/taskAssignment'
import { fetchStaffAssignmentStart } from 'store/staff/actions'

function* getOne(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOne, payload)
    yield put(fetchOneTaskAssignmentSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchOneTaskAssignmentFail())
  }
}

function* getOneSlim1(action) {
  const { payload } = action

  try {
    const response = yield call(fetchOneSlim1, payload)
    yield put(fetchOneTaskAssignmentSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchOneTaskAssignmentFail())
  }
}

function* getManySync(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(fetchMany, request)

    if (afterAction) {
      afterAction()
    }

    yield put(fetchTaskAssignmentListSuccess(response.data.data.items))
  } catch (error) {
    yield put(fetchTaskAssignmentListFail())
  }
}

function* getMany(action) {
  const { payload } = action

  try {
    const response = yield call(fetchMany, payload)
    yield put(fetchTaskAssignmentListSuccess(response.data.data.item))
  } catch (error) {
    yield put(fetchTaskAssignmentListFail())
  }
}

function* createManyTaskAssignment(action) {
  const { payload } = action
  const { requestParams, afterAction } = payload

  try {
    yield call(createMany, requestParams)
    yield put(fetchStaffAssignmentStart())

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

function* updateTaskAssignment(action) {
  const { payload } = action

  try {
    yield call(update, payload)
    yield put(fetchOneTaskAssignmentStart({ id: payload.id }))
  } catch (error) {}
}

function* updateTaskAssignmentWithoutFetch(action) {
  const { payload } = action

  try {
    yield call(update, payload)
  } catch (error) {}
}

function* updateTaskAssignmentSync(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(update, request)

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

function* createTaskAssignment(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(create, request)
    yield put(
      fetchTaskAssignmentListStart({
        warehouse_staff_id: request.data.warehouse_staff_id,
      }),
    )

    if (afterAction) {
      afterAction(response.data.data.item)
    }
  } catch (error) {}
}

function* deleteTaskAssignment(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    const response = yield call(deleteOne, request)

    if (afterAction) {
      afterAction(response.data.data.item)
    }
  } catch (error) {}
}

function* createRestockTaskAssignment(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(create, request)

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

function* deleteManyTaskAssignment(action) {
  const { payload } = action
  const { requestParams, afterAction } = payload

  try {
    yield call(deleteMany, requestParams)
    yield put(fetchStaffAssignmentStart())

    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

function* fetchPauseReasonsList(action) {
  const { payload } = action

  try {
    const response = yield call(fetchPauseReasons, payload)
    yield put(fetchPauseReasonsSuccess(response.data.data.items))
  } catch (error) {}
}

function* completeOrderPickProcess(action) {
  const { payload } = action
  const { request, afterAction } = payload
  try {
    yield call(completeOrderPick, { data: request })
    yield put(completeOrderPickSuccess(true))
    if (afterAction) {
      afterAction()
    }
  } catch (error) {}
}

function* assignTeamToUnloadProcess(action) {
  const { payload } = action
  const { request, afterAction } = payload

  try {
    yield call(assignTeamToUnload, request)
    yield afterAction()
  } catch (error) {}
}

export default function* taskAssignmentSaga() {
  yield takeLatest(types.FETCH_ONE_TASK_ASSIGNMENT_START, getOne)
  yield takeLatest(types.FETCH_ONE_TASK_ASSIGNMENT_SLIM_1_START, getOneSlim1)
  yield takeLatest(types.FETCH_TASK_ASSIGNMENT_LIST_START, getMany)
  yield takeLatest(types.UPDATE_TASK_ASSIGNMENT, updateTaskAssignment)
  yield takeLatest(types.UPDATE_TASK_ASSIGNMENT_SYNC, updateTaskAssignmentSync)
  yield takeLatest(
    types.UPDATE_TASK_ASSIGNMENT_WITHOUT_FETCH,
    updateTaskAssignmentWithoutFetch,
  )
  yield takeLatest(types.CREATE_TASK_ASSIGNMENT, createTaskAssignment)
  yield takeLatest(types.DELETE_TASK_ASSIGNMENT, deleteTaskAssignment)
  yield takeLatest(
    types.CREATE_RESTOCK_TASK_ASSIGNMENT,
    createRestockTaskAssignment,
  )
  yield takeLatest(types.CREATE_MANY_TASK_ASSIGNMENT, createManyTaskAssignment)
  yield takeLatest(types.DELETE_MANY_TASK_ASSIGNMENT, deleteManyTaskAssignment)
  yield takeLatest(types.FETCH_PAUSE_REASONS_START, fetchPauseReasonsList)
  yield takeLatest(types.FETCH_TASK_ASSIGNMENT_LIST_SYNC_START, getManySync)
  yield takeLatest(types.COMPLETE_ORDER_PICK, completeOrderPickProcess)
  yield takeLatest(types.ASSIGN_TEAM_TO_UNLOAD, assignTeamToUnloadProcess)
}

// Note, this started as a copy of the RestockStorageToStorage page, minimal changes as of 2022.01
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import {
  fetchBulkRestockTaskStart,
  processBulkFromTask,
} from 'store/restockProcess/actions'
import Active from 'assets/svg/active--ipad.svg'
import ActiveGrey from 'assets/svg/active-grey--ipad.svg'
import Scanner from 'components/common/Scanner'
import { closeModal, resetModals, showModal } from 'store/modal/actions'
import { isEmptyObject, redirect, date, dateToFormat } from 'helpers/index'
import { BULK_STORAGE_TASK_LIST } from 'constants/routes'
import ExclamationSign from 'assets/svg/exclamation.svg'
import { updateTaskAssignmentWithoutFetch } from 'store/taskAssignment/actions'

const StartBulkStoragePage = (props) => {
  const {
    match,
    currentTask,

    resetModals,
    closeModal,
    showModal,
    fetchBulkRestockTask,
    processBulkFromTask,
  } = props

  const taskId = match.params.id
  const processId = currentTask.process_id

  const onMount = () => {
    showModal({
      name: 'modalPending',
    })
    fetchBulkRestockTask({
      id: taskId,
    })
  }

  const [locationTitle, setLocationTitle] = useState('')
  const [restockLocation, setRestockLocation] = useState({})

  const [isScanned, setIsScanned] = useState(false)

  const onUpdateCurrentTask = () => {
    if (!isEmptyObject(currentTask)) {
      const firstAndSingleRestockLocation = currentTask.entity.restock_location
      setRestockLocation(firstAndSingleRestockLocation)
    }
  }

  const onUpdateRestockLocation = () => {
    if (!isEmptyObject(restockLocation)) {
      setLocationTitle(
        [
          restockLocation['warehouse_location_from'].regular_location.aisle
            .name,
          restockLocation['warehouse_location_from'].regular_location.bay.name,
          restockLocation['warehouse_location_from'].regular_location.level,
        ].join('-'),
      )
    }
  }

  const onUpdate = () => {
    if (locationTitle) {
      closeModal('modalPending')
    }
  }

  useEffect(onMount, [])
  useEffect(onUpdateCurrentTask, [currentTask])
  useEffect(onUpdateRestockLocation, [restockLocation])
  useEffect(onUpdate, [locationTitle])

  if (
    isEmptyObject(currentTask) ||
    isEmptyObject(restockLocation) ||
    !locationTitle
  ) {
    return <></>
  }

  const onScan = (barcode) => {
    const warehouseCases =
      restockLocation.warehouse_location_from.warehouse_cases_in_location
    const warehousePallet = restockLocation.warehouse_pallets.find(
      (item) => item.pallet_upc.toString() === barcode.toString(),
    )

    switch (true) {
      case !warehousePallet:
        showModal({
          name: 'modalWarning',
          onAccept: resetModals,
          onDeny: resetModals,
          text: 'Wrong Pallet UPC',
          className: 'modal--tasks',
          modalIcon: (
            <ExclamationSign className="icon--restock icon--exclamation" />
          ),
        })
        break
      case warehousePallet.warehouse_case_id !== warehouseCases.id:
        showModal({
          name: 'modalWarning',
          onAccept: resetModals,
          onDeny: resetModals,
          text: 'Wrong Product',
          className: 'modal--tasks',
          modalIcon: (
            <ExclamationSign className="icon--restock icon--exclamation" />
          ),
        })
        break
      default:
        setIsScanned(true)

        processBulkFromTask({
          data: {
            from: {
              product_id: restockLocation.product_id,
              warehouse_location_id: restockLocation.warehouse_location_from_id,
              warehouse_case_id:
                restockLocation.warehouse_location_from
                  .warehouse_cases_in_location.id,
              pallet_case_qty: warehousePallet.qty_cases_on_pallet,
              location_case_qty:
                restockLocation.warehouse_location_from
                  .warehouse_cases_in_location.location_case_qty,
            },
            restock_location: {
              id: restockLocation.id,
              status_id: restockLocation.status_id,
            },
            task: {
              assignment_id: currentTask.restocker.taskAssignment.id,
            },
            process: {
              id: processId,
            },
            to: {
              warehouse_location_id: restockLocation.warehouse_location_to_id,
            },
            warehouse_pallet: {
              id: warehousePallet.id,
            },
          },
        })

        redirect(BULK_STORAGE_TASK_LIST)

        setTimeout(() => {
          setIsScanned(false)
        }, 1000)
    }
  }

  const getBottomContent = () => {
    let content = ''

    switch (true) {
      case isScanned:
        content = (
          <>
            Scan Pallet UPC <Active />
          </>
        )
        break
      case !isScanned:
        content = (
          <>
            Scan Pallet UPC <ActiveGrey />
          </>
        )
        break
      default:
        break
    }

    return (
      <div className="content__section--white">
        <span className="status">{content}</span>
      </div>
    )
  }

  return (
    <div className="tasks-page tasks-page--start-restock page--restock-relocation">
      <Header />
      <Scanner needToRemoveListener={false} onReceiveData={onScan} />
      <div className="content">
        <div className="content__section">
          <div className="dock__new-wrapper">
            <div className="dock">
              <span className="dock__label">LOC</span>
              <span className="dock__name">{locationTitle}</span>
            </div>
          </div>
          <h3 className="fireworks-name-new">{restockLocation.product_name}</h3>
          <ul className="fireworks-new">
            <li className="fireworks-new__item">
              <span className="fireworks__name">Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_model}
              </span>
            </li>
            <li className="fireworks-new__item">
              <span className="fireworks__name">Manf Model</span>
              <span className="fireworks__sub-name">
                {restockLocation.product.products_manf_model}
              </span>
            </li>
          </ul>
        </div>
        {getBottomContent()}
      </div>
    </div>
  )
}

StartBulkStoragePage.propTypes = {
  currentTask: PropTypes.object,

  resetModals: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.func,
  fetchBulkRestockTask: PropTypes.func,
}

const mapStateToProps = (state) => ({
  currentTask: state.restockProcess.task,
})

const mapDispatchToProps = {
  fetchBulkRestockTask: fetchBulkRestockTaskStart,
  processBulkFromTask,

  updateTaskAssignmentWithoutFetch,

  showModal: showModal,
  closeModal: closeModal,
  resetModals: resetModals,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StartBulkStoragePage)

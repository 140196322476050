import request, { requestWithRetry } from 'helpers'
import { API_URL } from 'constants/apiconfig'

const apiEndpoint = `${API_URL}task-assignments`

export const fetchOne = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'GET',
  })

export const fetchOneSlim1 = (payload) =>
  requestWithRetry({
    url: `${API_URL}task-assignments-slim-1/${payload.id}`,
    method: 'GET',
  })

export const update = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'PUT',
    data: payload,
  })

export const create = (payload) =>
  request({
    url: apiEndpoint,
    method: 'POST',
    data: payload,
  })

export const deleteOne = (payload) =>
  request({
    url: `${apiEndpoint}/${payload.id}`,
    method: 'DELETE',
    data: payload,
  })

export const fetchMany = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}`,
    method: 'GET',
    params: payload,
  })

export const createMany = (payload) =>
  request({
    url: `${apiEndpoint}/many`,
    method: 'POST',
    data: payload,
  })

export const deleteMany = (payload) =>
  request({
    url: `${apiEndpoint}/many`,
    method: 'DELETE',
    data: payload,
  })

export const fetchPauseReasons = (payload) =>
  requestWithRetry({
    url: `${apiEndpoint}/pause-reasons`,
    method: 'GET',
    data: payload,
  })

export const completeOrderPick = (payload) =>
  request({
    url: `${apiEndpoint}/complete-order-pick/${payload}`,
    method: 'PUT',
    data: payload,
  })

export const assignTeamToUnload = (payload) =>
  request({
    url: `${apiEndpoint}/assign-team-to-unload`,
    method: 'POST',
    data: payload,
  })

import React, { useEffect } from 'react'
import './style.scss'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import Header from 'components/common/Header'
import BulkStorageTaskContent from 'components/features/Task/BulkStorageTaskList/BulkStorageTaskContent'
import {
  fetchBulkRestockFromCount,
  fetchBulkRestockToCount,
} from 'store/homeAnalytics/actions'
import Scanner from 'components/common/Scanner'
import CloseButton from 'components/common/CloseButton'
import TaskTotalIcon from 'components/common/TaskTotalIcon'
import { SNACK_ERROR } from 'constants/snackbar'
import { useSnackbar } from 'notistack'
import { redirect } from 'helpers/index'
import {
  fetchRestockProcessListStart,
  startBulkStorageToTask,
} from 'store/restockProcess/actions'
import { STATUS_CREATED, STATUS_IN_PROGRESS } from 'constants/processStatuses'
import { closeModal, showModal, resetModals } from 'store/modal/actions'
import { RESTOCK_TYPE_GROUPS } from 'constants/taskAssignment'
import { BULK_RESTOCK_TO_STORAGE_PAGE } from 'constants/routes'

const BulkStorageTaskList = ({
  processList,
  fetchRestockProcessList,
  fetchBulkRestockFromCount,
  user,
  fetchBulkRestockToCount,
  startBulkStorageToTask,
  homeAnalytics,
  closeModal,
  showModal,
  resetModals,
}) => {
  const getAnalytics = () => {
    fetchBulkRestockFromCount()
    fetchBulkRestockToCount()
  }
  let listedItems = processList // note 2021.12.17 - this is a hack. Better to "RESET"/flush processList store data in Redux on page close. then watch processList just below and get rid of listedItems
  const onProcessListChange = () => {
    getAnalytics()
    if (listedItems) {
      closeModal('modalPending')
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  const onPageLoad = () => {
    listedItems = null
    showModal({ name: 'modalPending' })
    fetchRestockProcessList({
      filters: RESTOCK_TYPE_GROUPS.BULK_STORAGE_FILTER,
      conditions: {
        warehouse_staff_id: user.warehouseStaffId,
        statuses: [STATUS_CREATED, STATUS_IN_PROGRESS],
      },
    })
    getAnalytics()
  }

  const onStartAfterAction = (data) => {
    resetModals()
    if (data.success) {
      redirect(BULK_RESTOCK_TO_STORAGE_PAGE.replace(':id', data.item.id))
    } else {
      //pallet not found or already put away
      setTimeout(() => {
        enqueueSnackbar(data.error, SNACK_ERROR)
      }, 100)
    }
  }

  const onScan = (value) => {
    showModal({
      name: 'modalPending',
    })

    startBulkStorageToTask({
      request: {
        data: {
          pallet_upc: value,
        },
      },
      afterAction: onStartAfterAction,
    })
  }

  let timeoutId
  const reloadPage = () => {
    timeoutId = setTimeout(() => {
      fetchRestockProcessList({
        filters: RESTOCK_TYPE_GROUPS.BULK_STORAGE_FILTER,
        conditions: {
          warehouse_staff_id: user.warehouseStaffId,
          statuses: [STATUS_CREATED, STATUS_IN_PROGRESS],
        },
      })
      getAnalytics()
      reloadPage()
    }, 10000)
  }

  useEffect(() => {
    reloadPage()
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(onPageLoad, [])
  useEffect(onProcessListChange, [processList])

  return (
    <div className="page page--restock">
      <Header>
        <TaskTotalIcon
          styling="secondary"
          count={homeAnalytics.bulkRestockFromCount}
        />
        <TaskTotalIcon
          styling="primary"
          count={homeAnalytics.bulkRestockToCount}
        />
        <div className="title">Bulk Storage Task List</div>
        <CloseButton />
      </Header>
      <div className="content">
        <Scanner needToRemoveListener={false} onReceiveData={onScan} />
        <BulkStorageTaskContent items={processList} />
      </div>
    </div>
  )
}

BulkStorageTaskList.propTypes = {
  processList: PropTypes.array,
}

const mapStateToProps = (state) => ({
  processList: state.restockProcess.list,
  homeAnalytics: state.homeAnalyticsReducer,
})

const mapDispatchToProps = {
  fetchRestockProcessList: fetchRestockProcessListStart,
  fetchBulkRestockFromCount,
  fetchBulkRestockToCount,
  closeModal: closeModal,
  showModal: showModal,
  startBulkStorageToTask,
  resetModals,
}

export default connect(mapStateToProps, mapDispatchToProps)(BulkStorageTaskList)
